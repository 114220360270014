import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default ({ i }) => (
    <StaticQuery
        query={graphql`
            query {
                service1: file(relativePath: { eq: "service-1.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service2: file(relativePath: { eq: "service-2.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service3: file(relativePath: { eq: "service-3.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service4: file(relativePath: { eq: "service-4.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service5: file(relativePath: { eq: "service-5.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service6: file(relativePath: { eq: "service-6.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service7: file(relativePath: { eq: "service-7.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service8: file(relativePath: { eq: "service-8.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service9: file(relativePath: { eq: "service-9.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service10: file(relativePath: { eq: "service-10.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service11: file(relativePath: { eq: "service-11.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service12: file(relativePath: { eq: "service-12.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service13: file(relativePath: { eq: "service-13.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                service14: file(relativePath: { eq: "service-14.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service15: file(relativePath: { eq: "service-15.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service16: file(relativePath: { eq: "service-16.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service17: file(relativePath: { eq: "service-17.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service18: file(relativePath: { eq: "service-18.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service19: file(relativePath: { eq: "service-19.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service20: file(relativePath: { eq: "service-20.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service21: file(relativePath: { eq: "service-21.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service22: file(relativePath: { eq: "service-22.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }

                service23: file(relativePath: { eq: "service-23.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => {
            return <Img fluid={data['service' + i].childImageSharp.fluid} />
        }}
    />
)
