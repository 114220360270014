import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import Form from '../form'

import Input from '../input'

import styles from './style.module.scss'

import Select from '../select'

import Button from '../button'

import Image from './Image'

import Card from '../card'

import cn from 'classnames'

import RelatedPosts from '../related-posts'

const SERVICES = [
    'Home Maintenance',
    'Maid services',
    'Property Staging',
    'Event Organizing',
    'Pest Control',
    'Gardening & Landscaping',
    'Moving & Relocation',
    'International Relocation',
    'Storage',
    'Property Furnishing',
    'Property Inspection',
    'Property Handover',
    'Pet Grooming',
    'Home Renovation',
    'Swimming Pool Maintenance',
    'Home Appliance Repair & Installation',
    'Property Photography & Videography'
]

const CORPORATE_SERVICES = [
    'Translator',
    'Pro services',
    'VIP Notary Service',
    'Document Notarization',
    'Office Moving',
    'VIP Trustee Services'
]

export default ({ data }) => {
    return (
        <div className={styles.listPropertyForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>Home Services</h1>
                        <div className={cn('row', styles.reportSupplier)}>
                            <div className="col-xs-12 col-sm-8">
                                <p>
                                    Book your service directly with verified
                                    suppliers any where in Dubai. Where ever you
                                    are in the world, get connected directly to
                                    the city’s best contractors and suppliers to
                                    assist you with all your property needs.
                                </p>
                            </div>
                            <div
                                className={cn(
                                    styles.reportSupplierCol,
                                    'col-xs-12 col-sm-4'
                                )}
                            >
                                <Button
                                    link="mailto:info@propertyeportal.com"
                                    className={styles.topReportSupplier}
                                >
                                    Report a supplier
                                </Button>
                            </div>
                        </div>
                        <StaticQuery
                            query={graphql`
                                query {
                                    badgeBestChoice: file(
                                        relativePath: {
                                            eq: "badge-best-choice.png"
                                        }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    badgeEconCert: file(
                                        relativePath: {
                                            eq: "badge-econ-certified.png"
                                        }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    badgePepCert: file(
                                        relativePath: {
                                            eq: "badge-pep-certified.png"
                                        }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    paymentBank: file(
                                        relativePath: {
                                            eq: "payment-bank-transfer.png"
                                        }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    paymentCash: file(
                                        relativePath: { eq: "payment-cash.jpg" }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    paymentCreditCards: file(
                                        relativePath: {
                                            eq: "payment-credit-cards.png"
                                        }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    stageanyhome: file(
                                        relativePath: { eq: "stageanyhome.jpg" }
                                    ) {
                                        childImageSharp {
                                            fluid(maxWidth: 1000) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            `}
                            render={data => {
                                return (
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6">
                                            <div className="row middle-xs">
                                                <div className="col-xs-2 col-sm-1">
                                                    <Img
                                                        fluid={
                                                            data.badgeBestChoice
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.badge,
                                                            styles.bestChoice
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xs-2 col-sm-1">
                                                    <Img
                                                        fluid={
                                                            data.badgeEconCert
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.badge,
                                                            styles.econCert
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xs-2 col-sm-1">
                                                    <Img
                                                        fluid={
                                                            data.badgePepCert
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.badge,
                                                            styles.pepCert
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6">
                                            <div
                                                className={cn(
                                                    'row middle-xs end-md',
                                                    styles.fillMd
                                                )}
                                            >
                                                <div className="col-xs-4 col-sm-2">
                                                    <p
                                                        className={
                                                            styles.weAccept
                                                        }
                                                    >
                                                        We Accept:
                                                    </p>
                                                </div>
                                                <div className="col-xs-1">
                                                    <Img
                                                        fluid={
                                                            data.paymentBank
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.paymentBadge,
                                                            styles.bank
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xs-1">
                                                    <Img
                                                        fluid={
                                                            data.paymentCash
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.paymentBadge,
                                                            styles.cash
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xs-4">
                                                    <Img
                                                        fluid={
                                                            data
                                                                .paymentCreditCards
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        objectFit="contain"
                                                        className={cn(
                                                            styles.paymentBadge,
                                                            styles.cards
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <a
                                                href="https://www.stageanyhome.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Img
                                                    className={cn(
                                                        styles.stageanyhome
                                                    )}
                                                    fluid={
                                                        data.stageanyhome
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </a>
                                        </div>
                                    </div>
                                )
                            }}
                        />

                        <div className="row">
                            {SERVICES.map((s, i) => (
                                <div className="col-xs-6 col-sm-3">
                                    <Card
                                        label={s}
                                        onClick={() =>
                                            document
                                                .getElementById('form')
                                                .scrollIntoView()
                                        }
                                    >
                                        <Image i={i + 1} />
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <h2>Corporate Services</h2>

                        <div className="row">
                            {CORPORATE_SERVICES.map((s, i) => (
                                <div className="col-xs-6 col-sm-3" key={i}>
                                    <Card label={s}>
                                        <Image i={i + 18} />
                                    </Card>
                                </div>
                            ))}
                        </div>

                        <div className={cn('row', styles.reportSupplier)}>
                            <div className="col-xs-8">
                                <p>
                                    Home and corporate services are arranged and
                                    booked through verified suppliers, however
                                    not delivered by Propertyeportal.com
                                    directly. Propertyeportal.com is a public
                                    marketing portal and not a service provider.
                                    You are advised to negotiate the price and
                                    terms of service with the supplier directly.
                                </p>
                                <p>
                                    If the services you are looking for is not
                                    listed above (Plumbing services, Electrical
                                    Maintenance, AC Repair Service). Please
                                    leave your contact details and one of our
                                    specialists will be in touch with you
                                    accordingly.
                                </p>
                            </div>
                            <div
                                className={cn(
                                    styles.reportSupplierCol,
                                    'col-xs-4'
                                )}
                            >
                                <Button link="mailto:info@propertyeportal.com">
                                    Report a supplier
                                </Button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <Form
                                className={styles.form}
                                id="form"
                                requiredFields={['full_name', 'email', 'phone']}
                                errorMessageClass={styles.errorMessage}
                            >
                                <h2>Book home or corporate services now</h2>
                                <Input type="text" label="Full name" required />
                                <Input type="email" label="Email" required />
                                <Input type="text" label="Phone" required />
                                <Select label="Best time to call">
                                    <option value="Morning">Morning</option>
                                    <option value="Afternoon">Afternoon</option>
                                    <option value="Evening">Evening</option>
                                </Select>
                                <Input type="textarea" label="Address" />

                                <h4>
                                    We appreciate 24 hours advance booking to
                                    get you the best service at the best price.
                                </h4>
                                <Select
                                    name="choose-service"
                                    label="Choose your service?"
                                >
                                    <option value="Home Maintenance">
                                        Home Maintenance
                                    </option>
                                    <option value="Maid Services">
                                        Maid Services
                                    </option>
                                    <option value="Property Stagging">
                                        Property Stagging
                                    </option>
                                    <option value="Event Organizing">
                                        Event Organizing
                                    </option>
                                    <option value="Pest Control">
                                        Pest Control
                                    </option>
                                    <option value="Gardening &amp; Landscaping">
                                        Gardening &amp; Landscaping
                                    </option>
                                    <option value="Moving &amp; Relocation">
                                        Moving &amp; Relocation
                                    </option>
                                    <option value="International Relocation">
                                        International Relocation
                                    </option>
                                    <option value="Storage">Storage</option>
                                    <option value="Property Furnishing">
                                        Property Furnishing
                                    </option>
                                    <option value="Property Inspection">
                                        Property Inspection
                                    </option>
                                    <option value="Property Handover">
                                        Property Handover
                                    </option>
                                    <option value="Pet Grooming">
                                        Pet Grooming
                                    </option>
                                    <option value="Home Renovation">
                                        Home Renovation
                                    </option>
                                    <option value="Swimming Pool Maintenance">
                                        Swimming Pool Maintenance
                                    </option>
                                    <option value="Property Photography &amp; Videography Services">
                                        Property Photography &amp; Videography
                                        Services
                                    </option>
                                    <option value="Translator">
                                        Translator
                                    </option>
                                    <option value="Pro Services">
                                        Pro Services
                                    </option>
                                    <option value="VIP Notary Service">
                                        VIP Notary Service
                                    </option>
                                    <option value="Document Notarization">
                                        Document Notarization
                                    </option>
                                    <option value="Office Moving">
                                        Office Moving
                                    </option>
                                    <option value="VIP Trustee Services">
                                        VIP Trustee Services
                                    </option>
                                    <option value="Home Appliance Repair &amp; Installation">
                                        Home Appliance Repair &amp; Installation
                                    </option>
                                </Select>
                                <Input
                                    type="date"
                                    label="Pick inspection date and time"
                                />
                                <Input
                                    type="textarea"
                                    label="Special requirements or comments?"
                                />
                                <p>
                                    {' '}
                                    The booking will only be accepted and
                                    confirmed once you get an email from one of
                                    our client servicing agents with a
                                    confirmation
                                </p>
                                <Button className={styles.marginTopPlus}>
                                    Book now
                                </Button>

                                <RelatedPosts data={data} />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
