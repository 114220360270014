import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import SEO from '../components/seo'

import PropertyServicesForm from '../components/property-services-form'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Property services'
                }
            ]}
            marketTicker
            noSecondaryHeader
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <PropertyServicesForm data={data} />
        </Layout>
    )
}

export const query = graphql`
    {
        relatedPosts: allWordpressPost(
            filter: {
                categories: { elemMatch: { slug: { eq: "property-services" } } }
            }
            limit: 10
        ) {
            edges {
                node {
                    title
                    slug
                }
            }
        }
        allWordpressPage(filter: { slug: { eq: "property-services" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
